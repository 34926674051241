@import '@cognitiv/cassiopeia-ui/dist/taurus';

@import '@cognitiv/cassiopeia-ui/dist/luna';

.message {
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  word-break: break-word;
  color: $text-secondary;
}

.grow {
  flex-grow: 1;
}

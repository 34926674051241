@import '@cognitiv/cassiopeia-ui/dist/taurus';

.navigation {
  display: flex;
  align-items: center;
  background-color: $background-inverse-primary;
  min-height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  padding: 0 16px;
  gap: 16px;
  width: 100%;
  z-index: 10;
  color: $text-inverse-primary;

  .grow {
    flex-grow: 1;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  color: $text-inverse-primary;
  cursor: pointer;
  max-width: 500px;
  overflow: hidden;

  .active {
    color: $text-primary-normal;
  }

  &:hover {
    a,
    p,
    svg {
      color: $text-primary-hover;
    }

    &.static {
      cursor: initial;

      p {
        color: $text-primary !important;
      }
    }
  }

  &:active {
    a,
    p,
    svg {
      color: $text-primary-normal;
    }
  }

  a,
  p,
  svg {
    color: $text-inverse-primary;
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.name {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
  gap: 8px;
  min-width: 250px;

  p {
    margin: 0;
  }
}

.icon {
  width: 16px;
}

.icon:last-of-type {
  display: none;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
}

.loader {
  min-height: 80px;
  display: flex;
  align-items: center;

  img {
    margin: 0 auto;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500&family=Raleway:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap');
@import '@cognitiv/cassiopeia-ui/dist/taurus';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 13px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: $text-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $background-tertiary-normal;
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// firefox does not support changing the border-radius of the scrollbar thumb,
// styles not applied to all scrolls with "html" tag, "*" is needed
* {
  scrollbar-width: thin;
  scrollbar-color: $background-tertiary-normal transparent;
}

input::placeholder {
  color: $text-placeholder;
}

a:focus-visible {
  outline: 1px solid #3e96ed;
  z-index: 10;
}
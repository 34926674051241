.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 350px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 16px;
    margin-top: 36px;
    width: 392px;
  }

  .imageWrapper {
    display: inline-flex;
    align-items: center;
    gap: 15px;
  }
}
@import '@cognitiv/cassiopeia-ui/dist/taurus';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/public/background_login_page.png');
}

.login {
  width: 440px;
  padding: 48px 24px 24px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $background-secondary;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 40px;
      width: auto;
    }
  }
}

.errorWrapper {
  min-height: 32px;
}

.error {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 16px;
  font-size: 13px;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  color: $text-error;
  text-overflow: ellipsis;
  overflow: hidden;
}

// fix the background color for the input that causes 1password
.login input[data-com-onepassword-filled='light'],
.login input[data-com-onepassword-filled='dark'] {
  height: 100% !important;
  box-shadow: 0 0 0 1000px $background-field-normal inset;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
  }
}
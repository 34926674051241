@import '@cognitiv/cassiopeia-ui/dist/taurus';

.private {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1280px;
  background-color: $background-primary;
}

.public {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $background-secondary;
  height: 100%;
}

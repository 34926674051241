@import '@cognitiv/cassiopeia-ui/dist/taurus';

.wrapper {
  display: flex;
  flex-grow: 1;
  gap: 16px;
  height: 32px;
  margin-right: 32px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  text-wrap: nowrap;
  min-height: 32px;
  width: fit-content;
}
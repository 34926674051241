@import '@cognitiv/cassiopeia-ui/dist/taurus';

.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $background-inverse-primary;
  color: $text-inverse-primary;
  max-height: 100%;
  min-height: 100%;
  min-width: 50px;
  max-width: 50px;
  z-index: 10;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  overflow: hidden;

  &.open {
    min-width: 250px;
    max-width: 250px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  &.hidden {
    overflow: visible;
  }

  .position {
    position: relative;
  }

  .grow {
    flex-grow: 1;
  }

  .mainMenuItems {
    min-height: 265px;
  }

  .toggle {
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
  }

  .image {
    display: flex;
    align-items: flex-start;
    min-height: 50px;
    min-width: 200px;
    cursor: pointer;
  }

  .break {
    border-top: $border-inverse-primary-normal;
    border-radius: 1px;
    width: 100%;
  }
}

.version {
  display: block;
  margin: 16px 0;
  width: 50px;
  text-align: center;
  color: $text-inverse-primary;
  font-size: 11px;
  text-wrap: nowrap;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.version.open {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.switchWrapper {
  padding-left: 8px !important;

  & > div {
    min-width: 32px;
  }
}
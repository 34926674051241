@import '@cognitiv/cassiopeia-ui/dist/taurus';

.wrapper {
  display: flex;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: calc(100% - 50px);
  background: $background-primary;
  padding: 24px;
  z-index: 5;
}

.slim {
  width: calc(100% - 200px);
  margin-left: 200px;
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 20px 20px;
  background: $background-secondary;

  &.left {
    position: relative;
    border-radius: 8px 0 0 8px;
  }

  &.right {
    border-radius: 0 8px 8px 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 60px;
  gap: 16px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.break {
  width: 100%;
  border-top: $border-field-normal;
  border-radius: 1px;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow-y: auto;
}

.folders {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
  align-content: flex-start;
  overflow: auto;
  padding: 20px 0px;
}

.content {
  display: flex;
  flex-grow: 1;
}

.grow {
  flex-grow: 1;
}

.footer {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
}

.blur {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: $background-primary;
  opacity: 0.5;
}
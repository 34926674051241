@import '@cognitiv/cassiopeia-ui/dist/taurus';

.wrapper {
  position: relative;

  &:focus-visible {
    outline: none;
  }
}

.wrapper:hover {
  background: $background-inverse-primary-hover;
}

.wrapper:hover [data-role='menu'] {
  display: flex;
}

.wrapper:has(.menu:hover) {
  .group {
    background: $background-inverse-primary-hover;
  }
}

.menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  padding: 0 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: $background-inverse-primary-hover;
  width: 225px;

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    color: $text-inverse-primary;
    white-space: nowrap;
    min-height: 50px;
    max-height: 50px;
    line-height: 0;

    &:hover {
      color: $text-primary-hover;
    }

    &:active {
      color: $text-primary-normal;
    }

    &.selected {
      color: $text-primary-normal;
    }
  }

  .border {
    border: $border-inverse-primary-normal;
    border-top: none;
    border-radius: 1px;
  }
}

.content {
  margin: 10px 0;
}

.group,
.action {
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  color: $text-inverse-primary;
  background: $background-inverse-primary;
  white-space: nowrap;
  min-height: 50px;
  max-height: 50px;
  width: 50px;
  gap: 16px;
  padding-left: 16px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;

  & > div {
    min-width: 18px;
  }

  &:hover {
    background: $background-inverse-primary-hover;
  }

  &:active {
    background: $background-inverse-primary-hover;
  }
}

.group:hover [data-role='tooltip'],
.action:hover [data-role='tooltip'],
.page:hover [data-role='tooltip'] {
  display: block;
}

.group.open,
.action.open {
  width: 250px;
  padding: 0 16px;
}

.group.selected {
  color: $text-primary-normal;
}

.label {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: inherit;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.page {
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  color: $text-inverse-primary;
  text-decoration: none;
  white-space: nowrap;
  min-height: 34px;
  max-height: 34px;
  gap: 6px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;

  &:hover {
    color: $text-primary-hover;
  }

  &:active {
    color: $text-primary-normal;
  }
}

.page.open {
  justify-content: start;
}


.page.selected {
  color: $text-primary-normal;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: 10px;
  color: $text-contrast;
}

.link{
  &:focus-visible {
    outline: 1px solid #3e96ed;
    outline-offset: 0px;
    z-index: 10;
    position: relative;
    float: left;
  }
}

@import '@cognitiv/cassiopeia-ui/dist/taurus';

.folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100px;
  min-height: 100px;
  user-select: none;
  text-align: center;
  position: relative;

  & p {
    word-break: break-all;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    color: $background-tertiary-normal;

    &:hover {
      color: $background-tertiary-hover;
    }

    &:active {
      color: $background-tertiary-pressed;
    }
  }

  &.selected {
    svg {
      color: $background-primary-normal;

      &:hover {
        color: $background-primary-hover;
      }

      &:active {
        color: $background-primary-pressed;
      }
    }
  }
}

.empty {
  p {
    color: $text-primary-normal;
  }

  svg {
    color: $text-primary-normal;
  }

  &:hover {
    p {
      color: $text-primary-hover;
    }

    svg {
      color: $text-primary-hover;
    }
  }

  &:active {
    p {
      color: $text-primary-pressed;
    }

    svg {
      color: $text-primary-pressed;
    }
  }
}

.dropdown {
  display: flex;
  position: absolute;
  left: 100px;
  top: -3;
  flex-direction: column;
  background: $background-secondary;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.40);
  z-index: 10;
  width: 232px;
  border-radius: 6px;
  padding: 4px;

  .wrapper {
    padding: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  }
}


.break {
  border-top: $border-field-normal;
  border-radius: 1px;
  margin: 4px;
  width: 100%;
}

.groupName {
  display: flex;
  padding: 8px;
  color: #8C8C8C;
  font-size: 11px;
}
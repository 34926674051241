@import '@cognitiv/cassiopeia-ui/dist/taurus';

.table {
  display: flex;
  padding: 1px;
}

.row {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin: 0 0 4px 0;
}

.grow {
  flex-grow: 1;
}

.break {
  border: $border-field-normal;
  border-top: none;
  border-radius: 1px;
  width: 100%;
  margin-top: 16px;
}

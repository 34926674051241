@import '@cognitiv/cassiopeia-ui/dist/taurus';

.warning {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 18px;
  left: 0;
  right: 0;
  z-index: 2;
  height: 24px;
  padding: 5px 0;
  color: #fff;

  p {
    flex-grow: 1;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    text-align: center;
  }
}
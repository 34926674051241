@import '@cognitiv/cassiopeia-ui/dist/taurus';

.overlay {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 400px;
  max-width: 400px;
  height: calc(100% - 50px);
  background-color: $background-secondary;
  position: fixed;
  right: -400px;
  z-index: 11;
  top: 50px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: slide 0.4s forwards;

  @keyframes slide {
    100% {
      right: 0;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  color: $text-primary;
  padding-bottom: 16px;
}

.body {
  overflow-y: auto;
  padding-bottom: 20px;
}

.close {
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;

  flex-grow: 1;
  gap: 8px;

  h2 {
    font-weight: 500;
    font-size: 17px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  gap: 8px;
  padding: 1px;
}

.break {
  border-top: $border-field-disabled;
  border-radius: 1px;
  width: 100%;
  margin-bottom: 24px;
}
